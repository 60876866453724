.tracker-container {
    display: flex;
    justify-content: center;
    .tracker {
        border-radius: 4px;
        border: .125rem solid #506991;
        background-color: #506991;
        text-decoration: none;
        color: white;
        width: 240px;
        padding: .5em;
        &:hover { 
            border: .125rem solid #a5b1c4;
            background-color: #a5b1c4;
            cursor: pointer;
        }
    }
    i {
        font-size: 16px;
        padding: 0 .5em;
    }
}