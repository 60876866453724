@import url(https://meyerweb.com/eric/tools/css/reset/reset.css);
@import url(https://use.fontawesome.com/releases/v5.7.0/css/all.css);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://meyerweb.com/eric/tools/css/reset/reset.css);
@import url(https://use.fontawesome.com/releases/v5.7.0/css/all.css);
.App {
  font-family: 'Open Sans', sans-serif; }
  .App #main {
    margin-bottom: 3.2em; }
  .App .title {
    font-weight: 400;
    font-size: 30px;
    margin: 0 0 .5em; }
  .App section {
    padding: 2em 1em; }
  .App p {
    font-size: 14px;
    line-height: 1.5em;
    margin-bottom: .5em; }

#home .home-banner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #a5b1c4;
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 1em; }
  #home .home-banner .banner-img {
    width: 100%;
    opacity: .85;
    margin-right: .25em;
    object-fit: cover; }
  #home .home-banner .intro-container {
    padding: 2em 1.5em; }
  #home .home-banner #signature {
    padding: .5em 0em;
    height: 50px;
    width: 240px; }

#developer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  #developer h1 {
    font-size: 18px; }
  #developer h1, #developer h2 {
    color: #506380;
    letter-spacing: 0.125em;
    font-weight: 600;
    margin-bottom: 1em; }
  #developer #skills {
    border: 1px solid #E6ECF8;
    border-radius: .25em;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 2em;
    box-shadow: 0 5px 5px 0 rgba(233, 240, 243, 0.5), 0 0 0 1px #E6ECF8; }
    #developer #skills .container {
      padding: 1em .5em;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center; }
      #developer #skills .container ul {
        display: flex;
        flex-direction: column;
        align-items: center; }
      #developer #skills .container li {
        padding: .5em 0; }
  #developer #projects {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 1em;
    width: 80%; }
    #developer #projects .proj-container {
      padding: 1em;
      border: 1px solid #E6ECF8; }
    #developer #projects a img {
      padding: .5em;
      width: 100%;
      opacity: .8; }
      #developer #projects a img:hover {
        cursor: pointer;
        opacity: .7; }

#treatsbyalice #discover {
  display: flex;
  justify-content: center;
  width: 100%; }
  #treatsbyalice #discover > a {
    width: 1080px; }
  #treatsbyalice #discover img {
    object-fit: cover;
    max-height: 400px;
    width: 100%;
    opacity: .8; }
    #treatsbyalice #discover img:hover {
      opacity: 1; }

#treatsbyalice #ad {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #506991;
  width: 100%;
  letter-spacing: .125em;
  margin: 1em 0; }
  #treatsbyalice #ad i.fab {
    font-size: 20px;
    padding: .5em; }
  #treatsbyalice #ad h2 {
    margin-bottom: .5em; }
  #treatsbyalice #ad > div {
    text-decoration: none;
    color: #506991;
    margin-bottom: 1em; }
  #treatsbyalice #ad b {
    font-weight: 600; }
  #treatsbyalice #ad div.gallery-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
    margin: 1em 0; }
    #treatsbyalice #ad div.gallery-container img {
      max-width: 160px;
      padding: .25em .25em 0 .25em;
      opacity: .75; }
      #treatsbyalice #ad div.gallery-container img:hover {
        opacity: 1; }

@media (min-width: 996px) {
  .App p {
    font-size: 18px;
    font-weight: 300; }
  .App .title {
    font-size: 40px; }
  #home .home-banner {
    flex-direction: row; }
    #home .home-banner .banner-img {
      width: 50%; }
    #home .home-banner #signature {
      height: 65px;
      width: 280px; }
  #developer #skills {
    flex-direction: row; }
    #developer #skills .container {
      padding: 2em .5em; }
      #developer #skills .container:not(:first-of-type) {
        border-left: 1px solid #E6ECF8; }
  #treatsbyalice #ad i.fab {
    font-size: 24px; }
  #treatsbyalice #ad div.gallery-container {
    margin: 1em; }
    #treatsbyalice #ad div.gallery-container img {
      max-width: 262px; }
    #treatsbyalice #ad div.gallery-container.gallery-img {
      padding-left: .5em; } }

.navbar-container {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border-bottom: .25em solid black;
  height: 2.5em;
  padding: 1em 2em;
  margin-bottom: 0; }
  .navbar-container .navbar-logo {
    display: flex;
    align-self: center;
    height: 80%;
    width: auto;
    padding: .25em; }
  .navbar-container .navbar-title {
    align-self: center;
    line-height: inherit;
    font-weight: bold;
    font-size: 20px; }
  .navbar-container .menu-icon {
    background: none;
    border: none;
    display: block; }
    .navbar-container .menu-icon .fa-bars {
      color: #363333;
      width: 3em;
      font-size: 1.5em; }
  .navbar-container .navbar-mobile {
    display: none; }
  .navbar-container .navbar-mobile.show {
    display: block;
    height: 100vh;
    width: 100vw;
    position: absolute;
    padding: 20px 0;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: white;
    border-left: 2px solid #3E4F64;
    -webkit-transform: translate(0);
            transform: translate(0);
    -webkit-transition: -webkit-transform 200ms ease;
    transition: -webkit-transform 200ms ease;
    transition: transform 200ms ease;
    transition: transform 200ms ease, -webkit-transform 200ms ease; }
    .navbar-container .navbar-mobile.show .navbar-mobile-menu-item {
      height: 50px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .navbar-container .navbar-mobile.show .navbar-mobile-menu-item a {
        text-decoration: none;
        font-weight: 600;
        color: #3E4F64; }

@media (min-width: 668px) {
  .navbar-container {
    width: auto;
    border-bottom: .2em solid black;
    justify-content: center; }
    .navbar-container .navbar-title-container {
      justify-content: center; }
    .navbar-container .navbar-menu {
      display: flex;
      width: 22.5em; }
    .navbar-container .navbar-menu-link {
      display: flex; }
    .navbar-container .menu-icon {
      display: none; } }

.subheader-container {
  display: none; }

.subheader-menu {
  display: flex;
  justify-content: center; }

.subheader-menu-link {
  display: flex;
  justify-content: space-between; }
  .subheader-menu-link a {
    font-size: 14px;
    color: #ffffff;
    text-decoration: none;
    padding: 1.25em;
    letter-spacing: 0.125em; }
    .subheader-menu-link a.active {
      background-color: rgba(165, 177, 196, 0.6); }
  .subheader-menu-link:hover {
    background-color: rgba(165, 177, 196, 0.2); }

@media (min-width: 668px) {
  .subheader-container {
    display: block;
    background-color: #2b3033;
    border-bottom: .2em solid black;
    width: 100%; } }

.footer-container {
  display: flex;
  background-color: #2b3033;
  justify-content: center;
  position: fixed;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 3.2em; }

.footer-menu {
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .footer-menu .footer-menu-link {
    justify-content: center;
    display: flex; }
    .footer-menu .footer-menu-link a {
      font-size: 14px;
      color: #ffffff;
      text-decoration: none;
      letter-spacing: 0.125em;
      padding: 1.25em; }
    .footer-menu .footer-menu-link button {
      background-color: transparent;
      color: white;
      border: none;
      font-size: 14px;
      letter-spacing: 0.125em;
      line-height: 14px;
      padding: 1em; }
      .footer-menu .footer-menu-link button:hover {
        background-color: rgba(165, 177, 196, 0.2);
        cursor: pointer; }
      .footer-menu .footer-menu-link button:focus {
        outline: none;
        box-shadow: none; }
    .footer-menu .footer-menu-link i {
      padding: 0 .25em;
      font-size: 20px; }
    .footer-menu .footer-menu-link span {
      display: none; }
    .footer-menu .footer-menu-link #emailtext {
      opacity: 0;
      height: 5px;
      width: 5px;
      left: 0;
      position: absolute; }
    .footer-menu .footer-menu-link:hover {
      background-color: rgba(165, 177, 196, 0.2); }

@media (min-width: 668px) {
  .footer-menu .footer-menu-link i {
    padding: 0 .5em;
    font-size: 16px; }
  .footer-menu .footer-menu-link span {
    display: inline; } }

.resume-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .75em;
  margin-bottom: 1em;
  max-width: 100%;
  background-color: #a5b1c4;
  color: white; }
  .resume-container i {
    padding: 0 .5em;
    font-size: 16px; }
  .resume-container a {
    color: white;
    text-decoration: none; }
    .resume-container a:hover {
      text-decoration: underline; }

.tracker-container {
  display: flex;
  justify-content: center; }
  .tracker-container .tracker {
    border-radius: 4px;
    border: .125rem solid #506991;
    background-color: #506991;
    text-decoration: none;
    color: white;
    width: 240px;
    padding: .5em; }
    .tracker-container .tracker:hover {
      border: .125rem solid #a5b1c4;
      background-color: #a5b1c4;
      cursor: pointer; }
  .tracker-container i {
    font-size: 16px;
    padding: 0 .5em; }

