//clears browser css
@import url(https://meyerweb.com/eric/tools/css/reset/reset.css);
@import url(https://use.fontawesome.com/releases/v5.7.0/css/all.css);
//Google font import
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.App{
    font-family: 'Open Sans', sans-serif;
    #main {
        margin-bottom: 3.2em;
    }
    
    .title{
        font-weight: 400;
        font-size: 30px;
        margin: 0 0 .5em;
    }

    section{
        padding: 2em 1em;
    }
    
    p{
        font-size: 14px;
        line-height: 1.5em;
        margin-bottom: .5em;
    }
}

#home{
    .home-banner{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #a5b1c4;
        color: #ffffff;
        font-size: 18px;
        margin-bottom: 1em;

        .banner-img{
            width: 100%;
            opacity: .85;
            margin-right: .25em;
            object-fit: cover;
        }

        .intro-container{
            padding: 2em 1.5em;
        }

        #signature {
            padding: .5em 0em;
            height: 50px;
            width: 240px;
        }
    }
}

#developer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
        font-size: 18px;
    }
    h1, h2 { 
        color: #506380;
        letter-spacing: 0.125em;
        font-weight: 600;
        margin-bottom: 1em;
    }
    #skills {
        border: 1px solid #E6ECF8;
        border-radius: .25em;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-bottom: 2em;
        box-shadow: 0 5px 5px 0 rgba(233, 240, 243, 0.5), 0 0 0 1px #E6ECF8;
        .container {
            padding: 1em .5em;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            ul {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            li {
                padding: .5em 0;
            }
        }
    }
    #projects {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-bottom: 1em;
        width: 80%;

        .proj-container{
            padding: 1em;
            border: 1px solid #E6ECF8;
        }
        a{
            img{
                padding: .5em;
                width:100%;
                opacity: .8;

                &:hover{
                    cursor: pointer;
                    opacity: .7;
                }
            }
        }
    }
}

#treatsbyalice{
    #discover {
        display: flex;
        justify-content: center;
        width: 100%;
        >a {
            width: 1080px;
        }
        img {
            object-fit: cover;
            max-height: 400px;
            width: 100%;
            opacity: .8;
            &:hover{
                opacity: 1;
            }
        }

    }
    #ad{
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color:#506991;
        width: 100%;
        letter-spacing: .125em;
        margin: 1em 0;
        i.fab {
            font-size: 20px;
            padding: .5em;
        }
        h2 {
            margin-bottom: .5em;
        }
        > div {
            text-decoration: none;
            color: #506991;
            margin-bottom: 1em;
        }
        b {
            font-weight: 600;
        }
        div.gallery-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            max-width: 100%;
            margin: 1em 0;
            img {
                max-width: 160px;
                padding: .25em .25em 0 .25em;
                opacity: .75;
                &:hover{
                    opacity: 1;
                }
            }
        }
    }
}


@media (min-width: 996px) {
    .App {
        p{
            font-size: 18px;
            font-weight: 300;
        }
        .title {
            font-size: 40px;
        }
    }
    #home{
        .home-banner{
            flex-direction: row;
            .banner-img{
                width: 50%;
            }
            #signature {
                height: 65px;
                width: 280px;
            }
        }
    }
    #developer {
        #skills {
            flex-direction: row;
            .container {
                padding: 2em .5em;
                &:not(:first-of-type) {
                    border-left: 1px solid #E6ECF8;
                }
            }
        }
    }
    #treatsbyalice {
        #ad {
            i.fab {
                font-size: 24px;
            }
            div.gallery-container { 
                margin: 1em;
                img {
                    max-width: 262px;
                }
                &.gallery-img{
                    padding-left: .5em;
                }
            }
        }
    }
}