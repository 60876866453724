.navbar-container{
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    border-bottom: .25em solid black;
    height: 2.5em;
    padding: 1em 2em;
    margin-bottom: 0;

    .navbar-logo{
        display: flex;
        align-self: center;
        height: 80%;
        width: auto;
        padding: .25em;
    }

    .navbar-title{
        align-self: center;
        line-height: inherit;
        font-weight: bold;
        font-size: 20px;
    }

    .menu-icon{
        background: none;
        border: none;
        display: block;
        .fa-bars{
            color: #363333;
            width: 3em;
            font-size: 1.5em;
        }
    }
    .navbar-mobile{
        display: none;
    }
    .navbar-mobile.show {
        display: block;
        height: 100vh;
        width: 100vw;
        position: absolute;
        padding: 20px 0;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: white;
        border-left: 2px solid #3E4F64;
        
        transform: translate(0);
        transition: transform 200ms ease;
        .navbar-mobile-menu-item{
            height: 50px;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            a {
                text-decoration: none;
                font-weight: 600;
                color: #3E4F64;
            }
        }
    }
}



// full-screen / desktop
@media (min-width: 668px) {
    .navbar-container{
        width: auto;
        border-bottom: .2em solid black;
        justify-content: center;

        .navbar-title-container{
            justify-content: center;
        }

        .navbar-menu{
            display: flex;
            width: 22.5em;
        }

        .navbar-menu-link{
            display: flex;
        }

        .menu-icon{
            display: none;
        }
    }

    
}