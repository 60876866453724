.footer-container{
    display: flex;
    background-color: #2b3033;
    justify-content: center;
    position: fixed;
    align-items: center;
    bottom: 0;
    width: 100%;
    height: 3.2em;
}
.footer-menu{
    display: flex;
    flex-direction: row;
    justify-content: center;
    .footer-menu-link{
        justify-content: center;
        display: flex;
        a {
            font-size: 14px;
            color: #ffffff;
            text-decoration: none;
            letter-spacing: 0.125em;
            padding: 1.25em;
        }
        button {
            background-color: transparent;
            color: white;
            border: none;
            font-size: 14px;
            letter-spacing: 0.125em;
            line-height: 14px;
            padding: 1em;
            &:hover {
                background-color: rgba(165, 177, 196, 0.2);
                cursor: pointer;
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
        i {
            padding: 0 .25em;
            font-size: 20px;
        }
        span {
            display: none;
        }
        #emailtext {
            opacity: 0;
            height: 5px;
            width: 5px;
            left: 0;
            position: absolute;
        }
        &:hover{
            background-color: rgba(165, 177, 196, 0.2);
        }
    }
}

@media (min-width: 668px) {
    .footer-menu{
        .footer-menu-link{
            i {
                padding: 0 .5em;
                font-size: 16px;
            }
            span {
                display: inline;
            }
        }
    }
}