//clears browser css
@import url(https://meyerweb.com/eric/tools/css/reset/reset.css);
@import url(https://use.fontawesome.com/releases/v5.7.0/css/all.css);

// mobile-first
.subheader-container{
    display:none;
}

.subheader-menu{
    display: flex;
    // line-height: initial;
    justify-content: center;
}

.subheader-menu-link{
    display: flex;
    justify-content: space-between;
    a{
        font-size: 14px;
        color: #ffffff;
        text-decoration: none;
        padding: 1.25em;
        letter-spacing: 0.125em;
        &.active{
            background-color: rgba(165, 177, 196, .6);
        }
    }
    &:hover{
        background-color: rgba(165, 177, 196, 0.2);
    }
}

@media (min-width: 668px) {
    .subheader-container{
        display: block;
        background-color: #2b3033;
        border-bottom: .2em solid black;
        width: 100%;
    }
}